@import 'common';

.BrainhubCarousel__arrows,
body {
  font-variant: normal !important;
}
.PracticePage {
  @include flex(column, flex-start, center);
  width: 100%;
  .Header {
    @include flex(row, space-between, center);
    padding: 10px;
    .logo {
      height: 90px;
    }
    .right {
      @include flex(row, flex-end, center);
    }
  }

  .body {
    width: 100%;

    .section {
      @media only screen and (max-width: 600px) {
        @include flex(column, flex-start, flex-start);
      }
      @media only screen and (min-width: 600px) {
        @include flex(row, flex-start, flex-start);
      }

      width: 100%;
      padding: calc(4vw - 5px);
      border-top: 1px solid #00000030;
      &:nth-child(odd) {
        background: $light-grey;
      }
      &:nth-child(odd) {
        background: white;
      }
      .sectiontitle {
        width: 180px;
        @media only screen and (max-width: $small-max) {
          width: 100%;
        }

        margin-right: calc(5vw - 10px);
        margin-bottom: calc(5vw - 10px);
      }
      .sectionitem {
        min-width: 250px;
        max-width: 100%;
        // margin-right: calc(9vw - 20px);
        & > * {
          width: 100%;
        }
        flex: 1;
        .infobox {
          @media only screen and (min-width: 1500px) {
            columns: 2;
          }
        }
      }

      .sectionitem.map {
        @include shadow(2px, 5px, 0.3);
        flex: 1;
        height: 350px;
        min-width: 225px;
        border-radius: 8px;
        overflow: hidden;
      }
      .captioned {
        @include flex(column, flex-end, center);
        &:hover {
          .caption {
            color: white;
            background-color: #00000080;
          }
        }
        .caption {
          color: transparent;
          background-color: transparent;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 14pt;
        }
      }
    }
  }
}

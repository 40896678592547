@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat);


/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  border: none;
  border-top: 1px #cccccc solid;
  height: 1px; }

small {
  font-size: 80%; }

.CenterContainer {
  display: flex;
  justify-content: center;
  align-items: center; }

.showsmall,
.showmedium,
.showlarge,
.showxlarge {
  display: none !important; }

.hidesmall,
.hidemedium,
.hidelarge,
.hidexlarge {
  display: block !important; }

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .showsmall {
    display: block !important; }
  .hidesmall {
    display: none !important; } }

@media only screen and (min-width: 500px) and (max-width: 800px) {
  .showmedium {
    display: block !important; }
  .hidemedium {
    display: none !important; } }

.wide {
  width: 100%; }

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .showlarge {
    display: block !important; }
  .hidelarge {
    display: none !important; } }

@media only screen and (min-width: 1200px) {
  .showxlarge {
    display: block !important; }
  .hidexlarge {
    display: none !important; } }

.fill {
  width: 100%;
  height: 100%; }

.fullpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh; }

.wordbreak {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid; }

.linkbutton {
  color: #2c92ff; }
  .linkbutton:hover {
    cursor: pointer;
    color: #1385ff;
    text-decoration: underline; }

.textbutton {
  color: #1e1e1e;
  opacity: 0.8;
  cursor: pointer;
  margin: 0px; }
  .textbutton:hover {
    opacity: 1; }

.wrapspacer {
  height: 3px; }

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 500px) {
    .row {
      flex-wrap: wrap; } }

.leftrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap; }

.centercol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap; }

.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  -webkit-align-items: flex-start; }
  .col > * {
    text-align: left;
    margin-left: 0px; }

.wrap {
  flex-wrap: wrap; }

.drawer {
  margin: 3vw; }

.whitebg {
  background-color: white; }

.shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3); }

.p5 {
  padding: 5px; }

.p10 {
  padding: 10px; }

.p15 {
  padding: 15px; }

.p20 {
  padding: 20px; }

.clickable {
  opacity: 0.8;
  cursor: pointer; }
  .clickable:hover {
    opacity: 1; }

.thumbnail,
.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 4px; }
  .thumbnail:hover,
  .upload:hover {
    border: 1px dashed #2c92ff; }

.thumbnail {
  height: 200px; }

.upload {
  height: 50px;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.03); }
  .upload:hover {
    background-color: rgba(0, 0, 0, 0.06); }

.table {
  border-radius: 4px;
  max-width: 600px;
  border: 1px solid rgba(44, 146, 255, 0.25); }
  .table p {
    margin: 0px; }
  .table > * {
    padding: 4px 8px; }
    .table > *:nth-child(odd) {
      background-color: rgba(44, 146, 255, 0.08); }

p.faint {
  font-size: 75%;
  opacity: 0.65;
  color: #1e1e1e;
  margin: 4px 0px;
  line-height: 100%;
  display: inline-block; }

p.bold {
  font-weight: 600;
  font-size: 110%;
  opacity: 0.65;
  color: #1e1e1e; }

.alert {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: rgba(44, 146, 255, 0.02);
  border: 1px solid rgba(44, 146, 255, 0.16); }

.photo-pop {
  transition: 0.15s;
  box-shadow: 0px 1px 2px #00000080;
  height: 150px; }
  .photo-pop:hover {
    box-shadow: 0px 1px 7px #00000080; }

.banner {
  color: white;
  text-shadow: 0px 1px 6px #000000b0, 0px 0px 10px #00000080; }

.card {
  padding: 10px 20px;
  background-color: rgba(44, 146, 255, 0.03);
  border: 1px solid rgba(44, 146, 255, 0.3);
  border-radius: 4px;
  margin: 10px 0px; }

.sidebar .ant-anchor-wrapper {
  background-color: transparent;
  margin: 0px 0px 0px 4px;
  overflow: hidden; }

html,
body {
  font-family: 'Montserrat', 'sans-serif';
  background-color: white;
  box-sizing: border-box; }

p {
  margin-bottom: 4px;
  text-align: left; }

input.cstm,
textarea.cstm,
button.cstm {
  border: none;
  border-radius: 4px; }

input.cstm,
textarea.cstm {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  padding: 7px 5px 4px 5px;
  background: white;
  line-height: 1.2;
  width: 100%;
  max-width: 450px; }
  input.cstm p,
  textarea.cstm p {
    margin: 0px; }

button.cstm,
input.cstm[type='submit'] {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  padding: 6px 10px 5px 10px;
  position: relative;
  top: 0px;
  background: #2c92ff;
  color: white;
  cursor: pointer; }
  button.cstm:hover,
  input.cstm[type='submit']:hover {
    top: 1px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    background: #1385ff; }
  button.cstm > *,
  input.cstm[type='submit'] > * {
    position: static; }

label.cstm {
  font-size: 75%;
  opacity: 0.65;
  color: #1e1e1e;
  margin: 4px 0px;
  line-height: 100%;
  display: inline-block; }

textarea.cstm {
  resize: both; }

h1 {
  font-size: calc(0.4rem + 8px + 5 * 3px); }

h2 {
  font-size: calc(0.4rem + 8px + 4 * 3px); }

h3 {
  font-size: calc(0.4rem + 8px + 3 * 3px); }

h4 {
  font-size: calc(0.4rem + 8px + 2 * 3px); }

h5 {
  font-size: calc(0.4rem + 8px + 1 * 3px); }

h6 {
  font-size: calc(0.4rem + 8px + 0 * 3px); }

iframe {
  width: 100%;
  height: 100%; }

.Footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px 30px;
  background-color: #111;
  color: #ffffff88; }

.Footer a {
  color: #ffffff88;
  margin: 2px 6px; }
  .Footer a:hover {
    text-decoration: underline;
    color: #ffffff88; }

.Footer > div {
  max-width: 300px; }

.Footer .logo {
  width: 150px; }

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #1e1e1e;
  color: #e9e9e9;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 9.5pt;
  height: 140px; }
  .Header:hover {
    color: white; }

.Header img {
  height: 100px; }

.Header a {
  padding: 16px 15px;
  color: #e9e9e9;
  text-transform: uppercase;
  font-weight: 600; }
  .Header a:hover {
    color: white; }

.Header .spacer {
  flex: 1 1; }

.iconcircle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 1px 2px lightgrey;
  opacity: 0.8; }
  .iconcircle:hover {
    opacity: 1; }

.InfoItem {
  margin-top: 7px; }

.IconItem .anticon {
  margin: 2px 4px 0px 0px; }

.MapControls {
  width: 100%;
  box-shadow: 0px 0px 3px 0px #888;
  z-index: 1; }
  .MapControls .SearchBox {
    width: 100%;
    border: none;
    padding: 10px;
    text-overflow: ellipses; }

.MapRow.row {
  width: 100%; }
  .MapRow.row .MapCol {
    width: 400px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #80808080;
    overflow: hidden; }

.MapContainer {
  width: 100%; }
  @media only screen and (max-width: 500px) {
    .MapContainer {
      height: 350px; } }
  @media only screen and (min-width: 500px) {
    .MapContainer {
      height: 450px; } }
  @media only screen and (min-width: 800px) {
    .MapContainer {
      height: 550px; } }

.PeopleList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap; }

.PersonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap; }
  .PersonRow img {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: calc(7vw + 40px);
    height: calc(7vw + 40px);
    border-radius: calc(1vw + 3px);
    margin-right: 15px; }

.PracticeDetails {
  height: 100%;
  flex: 1 1;
  min-width: 300px;
  background: white;
  box-shadow: 0px 1px 3px #80808080;
  border-radius: 5px;
  overflow: hidden;
  border-left: 1px solid lightgrey; }
  @media only screen and (max-width: 500px) {
    .PracticeDetails {
      margin: 10px 0px; } }
  @media only screen and (min-width: 500px) {
    .PracticeDetails {
      margin: 0px 10px; } }
  .PracticeDetails p {
    margin: 0px; }
  .PracticeDetails > div:first-child {
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #888; }
    .PracticeDetails > div:first-child h3 {
      margin-bottom: 7px; }
  .PracticeDetails > div:not(:first-child) {
    padding: 10px;
    background: #ffffff80; }

.Tag {
  display: inline-block;
  border-radius: 4px;
  font-size: 11px;
  padding: 2px 4px;
  margin-top: -3px; }
  .Tag.dark {
    color: white; }
  .Tag.light {
    color: black; }

.LockCover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #ffffff78;
  border-radius: 3px;
  z-index: 100000;
  color: #000000b0;
  border: 1px solid #00000050;
  overflow: hidden; }

@media only screen and (max-width: 500px) {
  .HomePage {
    margin: 10px 15px; } }

@media only screen and (min-width: 500px) {
  .HomePage {
    margin: 10px;
    margin: 10px calc(10px + 5vw); } }

@media only screen and (min-width: 800px) {
  .HomePage {
    margin: 10px;
    margin: 10px calc(10px + 10vw); } }

.HomePage > .row {
  align-items: flex-start; }

.row.HeaderRow {
  margin: 60px 0px; }
  .row.HeaderRow .col {
    max-width: 450px;
    align-items: center;
    text-align: center; }

.row.SearchRow {
  justify-content: flex-start; }

.row.AboutRow .col {
  align-items: flex-start; }

.row.AboutRow button.cstm {
  margin-bottom: 1em; }

.InfoRow {
  text-align: left; }
  .InfoRow p {
    margin-bottom: 10px; }
  .InfoRow h3 {
    margin-bottom: 10px; }

html,
body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden; }

.Dashboard {
  width: 100%;
  max-width: 1200px; }
  .Dashboard .desktop .dashheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0px 2vw;
    height: 82px;
    position: fixed;
    top: 0px; }
  .Dashboard .desktop .body {
    margin-top: 82px;
    margin-bottom: 50px;
    padding: 0px 2vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap; }
    .Dashboard .desktop .body .sidebar {
      position: fixed;
      width: 225px; }
    .Dashboard .desktop .body .contentwrapper {
      left: calc(225px + 20px);
      width: calc(100vw - 225px - 2vw - 1vw);
      height: calc(100vh - 82px - 1vw); }
  .Dashboard .mobile {
    padding: 0vw; }
    .Dashboard .mobile .dashheader {
      padding: 2vw;
      position: fixed;
      top: 0px;
      z-index: 3;
      height: calc(40px + 4vw); }
    .Dashboard .mobile .body {
      margin-top: calc(40px + 4vw);
      height: calc(100vh - 40px - 4vw);
      padding: 0vw; }
      .Dashboard .mobile .body .contentwrapper {
        border-radius: 0px;
        padding: 2vw;
        width: 100vw;
        height: 100%; }
  .Dashboard .dashheader {
    z-index: 2; }
  .Dashboard .contentwrapper {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px; }
    .Dashboard .contentwrapper .content {
      padding: 2vw;
      overflow: scroll; }
  .Dashboard .saveButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 5px;
    left: 0px;
    width: 100%; }

.sidebar {
  padding-left: 1vw;
  overflow: scroll;
  height: calc(100vh - 82px - 1vw); }
  .sidebar > div > div {
    width: 100%; }

.sidebaritem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  opacity: 0.8;
  text-align: left;
  width: 100%;
  margin: 6px 0px;
  cursor: pointer;
  color: #1e1e1e; }
  .sidebaritem:hover {
    opacity: 1; }
  .sidebaritem > p {
    margin-left: 12px;
    margin-bottom: 0px; }

.FireInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0px 10px 15px 0px;
  width: 100%;
  max-width: 600px;
  min-width: 130px; }

.BrainhubCarousel__arrows,
body {
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important; }

.PracticePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%; }
  .PracticePage .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 10px; }
    .PracticePage .Header .logo {
      height: 90px; }
    .PracticePage .Header .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap; }
  .PracticePage .body {
    width: 100%; }
    .PracticePage .body .section {
      width: 100%;
      padding: calc(4vw - 5px);
      border-top: 1px solid #00000030; }
      @media only screen and (max-width: 600px) {
        .PracticePage .body .section {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: nowrap; } }
      @media only screen and (min-width: 600px) {
        .PracticePage .body .section {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: nowrap; } }
      .PracticePage .body .section:nth-child(odd) {
        background: #f6f6f6; }
      .PracticePage .body .section:nth-child(odd) {
        background: white; }
      .PracticePage .body .section .sectiontitle {
        width: 180px;
        margin-right: calc(5vw - 10px);
        margin-bottom: calc(5vw - 10px); }
        @media only screen and (max-width: 500px) {
          .PracticePage .body .section .sectiontitle {
            width: 100%; } }
      .PracticePage .body .section .sectionitem {
        min-width: 250px;
        max-width: 100%;
        flex: 1 1; }
        .PracticePage .body .section .sectionitem > * {
          width: 100%; }
        @media only screen and (min-width: 1500px) {
          .PracticePage .body .section .sectionitem .infobox {
            -webkit-columns: 2;
                    columns: 2; } }
      .PracticePage .body .section .sectionitem.map {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
        flex: 1 1;
        height: 350px;
        min-width: 225px;
        border-radius: 8px;
        overflow: hidden; }
      .PracticePage .body .section .captioned {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap; }
        .PracticePage .body .section .captioned:hover .caption {
          color: white;
          background-color: #00000080; }
        .PracticePage .body .section .captioned .caption {
          color: transparent;
          background-color: transparent;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 14pt; }


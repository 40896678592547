.LockCover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #ffffff78;
  border-radius: 3px;
  z-index: 100000;
  color: #000000b0;
  border: 1px solid #00000050;
  overflow: hidden;
}

@import 'common';
.MapRow.row {
  width: 100%;

  .MapCol {
    width: 400px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #80808080;
    overflow: hidden;
  }
}

.MapContainer {
  width: 100%;

  @media only screen and (max-width: $small-max) {
    height: 350px;
  }
  @media only screen and (min-width: $small-max) {
    height: 450px;
  }
  @media only screen and (min-width: $medium-max) {
    height: 550px;
  }
}

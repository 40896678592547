.Footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px 30px;
  background-color: #111;
  color: #ffffff88;
}
.Footer a {
  color: #ffffff88;
  margin: 2px 6px;
  &:hover {
    text-decoration: underline;
    color: #ffffff88;
  }
}

.Footer > div {
  max-width: 300px;
}
.Footer .logo {
  width: 150px;
}

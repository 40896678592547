@import 'common';

.PeopleList {
  @include flex(column, flex-start, flex-start);
}
.PersonRow {
  @include flex(row, flex-start, flex-start);
  img {
    @include shadow(1px, 3px, 0.3);
    width: calc(7vw + 40px);
    height: calc(7vw + 40px);
    border-radius: calc(1vw + 3px);
    margin-right: 15px;
  }
}

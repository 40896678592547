.Tag {
  display: inline-block;
  border-radius: 4px;
  font-size: 11px;
  padding: 2px 4px;
  margin-top: -3px;
  &.dark {
    color: white;
  }
  &.light {
    color: black;
  }
}

.Header {
  @include flex(row, space-between, center, wrap);
  width: 100%;
  background-color: #1e1e1e;
  color: #e9e9e9;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 9.5pt;
  height: 140px;
  &:hover {
    color: white;
  }
}
.Header img {
  height: 100px;
}
.Header a {
  padding: 16px 15px;
  color: #e9e9e9;
  text-transform: uppercase;
  font-weight: 600;
  &:hover {
    color: white;
  }
}
.Header .spacer {
  flex: 1;
}

@import 'common';

.PracticeDetails {
  height: 100%;
  flex: 1;
  min-width: 300px;
  background: white;
  box-shadow: 0px 1px 3px #80808080;
  border-radius: 5px;
  overflow: hidden;
  border-left: 1px solid lightgrey;
  @media only screen and (max-width: $small-max) {
    margin: 10px 0px;
  }
  @media only screen and (min-width: $small-max) {
    margin: 0px 10px;
  }
  p {
    margin: 0px;
  }
  & > div:first-child {
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #888;
    h3 {
      margin-bottom: 7px;
    }
  }
  & > div:not(:first-child) {
    padding: 10px;
    background: #ffffff80;
  }
}

html,
body {
  font-family: 'Montserrat', 'sans-serif';
  background-color: lighten($blue-color, 44%);
  box-sizing: border-box;
}

p {
  margin-bottom: 4px;
  text-align: left;
}

input.cstm,
textarea.cstm,
button.cstm {
  border: none;
  border-radius: 4px;
}
input.cstm,
textarea.cstm {
  @include shadow();
  padding: 7px 5px 4px 5px;
  background: white;
  line-height: 1.2;
  & p {
    margin: 0px;
  }
  width: 100%;
  max-width: 450px;
}
button.cstm,
input.cstm[type='submit'] {
  @include shadow();
  padding: 6px 10px 5px 10px;
  position: relative;
  top: 0px;
  background: $blue-color;
  color: white;
  cursor: pointer;

  &:hover {
    top: 1px;
    @include shadow(0px);
    background: darken($blue-color, 5%);
  }
  & > * {
    position: static;
  }
}
label.cstm {
  font-size: 75%;
  opacity: 0.65;
  color: $dark-grey;
  margin: 4px 0px;
  line-height: 100%;
  display: inline-block;
}
textarea.cstm {
  resize: both;
}

$resp_font: 0.4rem;
$base_font: 8px;
$base_font_incr: 3px;
// $header-size-increment: 0.25rem;

h1 {
  font-size: calc(#{$resp_font} + #{$base_font} + 5 * #{$base_font_incr});
}
h2 {
  font-size: calc(#{$resp_font} + #{$base_font} + 4 * #{$base_font_incr});
}
h3 {
  font-size: calc(#{$resp_font} + #{$base_font} + 3 * #{$base_font_incr});
}
h4 {
  font-size: calc(#{$resp_font} + #{$base_font} + 2 * #{$base_font_incr});
}
h5 {
  font-size: calc(#{$resp_font} + #{$base_font} + 1 * #{$base_font_incr});
}
h6 {
  font-size: calc(#{$resp_font} + #{$base_font} + 0 * #{$base_font_incr});
}
iframe {
  width: 100%;
  height: 100%;
}

@import 'common';

$padding: 2vw;

html,
body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.Dashboard {
  width: 100%;
  max-width: 1200px;

  .desktop {
    .dashheader {
      @include flex(row, space-between, center);
      width: 100%;
      padding: 0px #{$padding};
      height: $header-height;
      position: fixed;
      top: 0px;
    }
    .body {
      margin-top: $header-height;
      margin-bottom: 50px;
      padding: 0px #{$padding};
      @include flex(row, flex-start, flex-start);

      .sidebar {
        position: fixed;
        width: $sidebar-width;
      }
      .contentwrapper {
        left: calc(#{$sidebar-width} + 20px);
        width: calc(100vw - #{$sidebar-width} - #{$padding} - 1vw);
        height: calc(100vh - #{$header-height} - 1vw);
      }
    }
  }
  .mobile {
    padding: 0vw;
    .dashheader {
      padding: #{$padding};
      position: fixed;
      top: 0px;
      z-index: 3;
      height: calc(40px + #{2 * $padding});
    }
    .body {
      margin-top: calc(40px + #{2 * $padding});
      height: calc(100vh - 40px - #{2 * $padding});
      padding: 0vw;

      .contentwrapper {
        border-radius: 0px;
        padding: #{$padding};
        width: 100vw;
        height: 100%;
        // height: calc(100vh - 40px - #{2 * $padding});
      }
    }
  }

  .dashheader {
    z-index: 2;
  }

  .contentwrapper {
    @include shadow(1px, 4px);
    @include flex(column, flex-start, flex-start, wrap);
    position: fixed;
    background: transparentize($color: white, $amount: 0.2);
    border-radius: 8px;
    .content {
      padding: 2vw;
      overflow: scroll;
    }
  }
  .saveButtonContainer {
    @include flex(row, center, center);
    position: absolute;
    bottom: 5px;
    left: 0px;
    width: 100%;
  }
}

.sidebar {
  padding-left: $sidebar-padding;
  & > div > div {
    width: 100%;
  }
  overflow: scroll;
  height: calc(100vh - #{$header-height} - 1vw);
}

.sidebaritem {
  @include flex(row, flex-start, center);
  opacity: 0.8;
  text-align: left;
  width: 100%;
  margin: 6px 0px;
  cursor: pointer;
  color: $dark-grey;
  &:hover {
    opacity: 1;
  }
  & > p {
    margin-left: 12px;
    margin-bottom: 0px;
  }
}

.FireInput {
  @include flex(column, flex-start, flex-start);
  padding: 0px 10px 15px 0px;
  // flex: 1;
  width: 100%;
  max-width: 600px;
  min-width: 130px;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat');
// @import '~slick-carousel/slick/slick.css';
// @import '~slick-carousel/slick/slick-theme.css';
// @import '~slick-carousel/slick/slick.css';
// @import '~slick-carousel/slick/slick-theme.css';

// $accent-color: #ff4f51;
$small-max: 500px;
$medium-max: 800px;
$large-max: 1200px;

$red-color: #e43a43;
$green-color: #31b42c;
$blue-color: #2c92ff;
$yellow-color: #f8f52d;
$light-grey: #f6f6f6;
$dark-grey: #1e1e1e;

$header-height: 82px;
$sidebar-width: 225px;
$sidebar-padding: 1vw;
$std-padding: 2vw;

:export {
  // accentColor: $accent-color;
  redColor: $red-color;
  greenColor: $green-color;
  blueColor: $blue-color;
  yellowColor: $yellow-color;
  lightGrey: $light-grey;
  darkGreyColor: $dark-grey;
  smallMax: $small-max;
  mediumMax: $medium-max;
  largeMax: $large-max;
}

@import 'mixins';

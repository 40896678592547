.MapControls {
  width: 100%;
  box-shadow: 0px 0px 3px 0px #888;
  z-index: 1;

  .SearchBox {
    width: 100%;
    border: none;
    padding: 10px;
    text-overflow: ellipses;
  }
}

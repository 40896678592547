@import 'common';
.iconcircle {
  @include flex(row, center, center);
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 1px 2px lightgrey;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

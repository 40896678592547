@mixin flex($dir: row, $just: center, $align: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin page() {
  @media only screen and (max-width: $small-max) {
    margin: 10px 15px;
  }
  @media only screen and (min-width: $small-max) {
    margin: 10px;
    margin: 10px calc(10px + 5vw);
  }
  @media only screen and (min-width: $medium-max) {
    margin: 10px;
    margin: 10px calc(10px + 10vw);
  }
}

@mixin shadow($y: 1px, $blur: 3px, $op: 0.3) {
  box-shadow: 0px $y $blur transparentize($color: black, $amount: 1 - $op);
}

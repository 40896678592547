@import 'common';

.HomePage {
  @include page();

  & > .row {
    align-items: flex-start;
  }
}

.row.HeaderRow {
  margin: 60px 0px;
  .col {
    max-width: 450px;
    align-items: center;
    text-align: center;
  }
}
.row.SearchRow {
  justify-content: flex-start;
}

.row.AboutRow {
  .col {
    align-items: flex-start;
  }
  button.cstm {
    margin-bottom: 1em;
  }
}
.InfoRow {
  text-align: left;
  p {
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 10px;
  }
}

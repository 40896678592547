.showsmall,
.showmedium,
.showlarge,
.showxlarge {
  display: none !important;
}
.hidesmall,
.hidemedium,
.hidelarge,
.hidexlarge {
  display: block !important;
}
@media only screen and (min-width: 0px) and (max-width: $small-max) {
  .showsmall {
    display: block !important;
  }
  .hidesmall {
    display: none !important;
  }
}
@media only screen and (min-width: $small-max) and (max-width: $medium-max) {
  .showmedium {
    display: block !important;
  }
  .hidemedium {
    display: none !important;
  }
}
.wide {
  width: 100%;
}
@media only screen and (min-width: $medium-max) and (max-width: $large-max) {
  .showlarge {
    display: block !important;
  }
  .hidelarge {
    display: none !important;
  }
}
@media only screen and (min-width: $large-max) {
  .showxlarge {
    display: block !important;
  }
  .hidexlarge {
    display: none !important;
  }
}

.fill {
  width: 100%;
  height: 100%;
}
.fullpage {
  @include flex(column, center, center);
  width: 100vw;
  height: 100vh;
}

.wordbreak {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  break-inside: avoid;
}

.linkbutton {
  color: $blue-color;
  &:hover {
    cursor: pointer;
    color: darken($blue-color, 5%);
    text-decoration: underline;
  }
}
.textbutton {
  color: $dark-grey;
  opacity: 0.8;
  cursor: pointer;
  margin: 0px;
  &:hover {
    opacity: 1;
  }
}
.wrapspacer {
  height: 3px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  @media only screen and (max-width: $small-max) {
    flex-wrap: wrap;
  }
}
.leftrow {
  @include flex(row, flex-start, flex-start);
}
.centercol {
  @include flex(column, flex-start, center);
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  @media only screen and (max-width: $small-max) {
    // min-width: 301px;
  }
  & > * {
    text-align: left;
    margin-left: 0px;
  }
}
.wrap {
  flex-wrap: wrap;
}
.drawer {
  margin: 3vw;
}

.whitebg {
  background-color: white;
}
.shadow {
  @include shadow(1px, 2px, 0.3);
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.clickable {
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.thumbnail,
.upload {
  @include flex(column, center, center);
  padding: 5px;
  border: 1px dashed transparentize(black, 0.9);
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    border: 1px dashed $blue-color;
  }
}
.thumbnail {
  height: 200px;
}
.upload {
  height: 50px;
  width: 200px;
  background-color: transparentize(black, 0.97);
  &:hover {
    background-color: transparentize(black, 0.94);
  }
}
.table {
  p {
    margin: 0px;
  }
  border-radius: 4px;
  max-width: 600px;
  border: 1px solid transparentize($blue-color, 0.75);
  & > * {
    padding: 4px 8px;
    &:nth-child(odd) {
      background-color: transparentize($blue-color, 0.92);
    }
  }
}
p.faint {
  font-size: 75%;
  opacity: 0.65;
  color: $dark-grey;
  margin: 4px 0px;
  line-height: 100%;
  display: inline-block;
}
p.bold {
  font-weight: 600;
  font-size: 110%;
  opacity: 0.65;
  color: $dark-grey;
}

.alert {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: transparentize($blue-color, 0.98);
  border: 1px solid transparentize($blue-color, 0.84);
}
.photo-pop {
  transition: 0.15s;
  box-shadow: 0px 1px 2px #00000080;

  height: 150px;

  &:hover {
    box-shadow: 0px 1px 7px #00000080;
    // flex-basis: 100%;
    // height: 200px;
  }
}
.banner {
  color: white;
  text-shadow: 0px 1px 6px #000000b0, 0px 0px 10px #00000080;
}
.card {
  padding: 10px 20px;
  background-color: transparentize($blue-color, 0.97);
  border: 1px solid transparentize($blue-color, 0.7);
  border-radius: 4px;
  margin: 10px 0px;
}
